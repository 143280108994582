import React from "react";
import { TrckdContext } from "../contexts/TrckdContext";
import { Modal } from "./Modal";
// import "../components/Subscribe.css"

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = { name: "", surname: "", email: "", phone: "", show: false };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  isEmpty(str) {
    return !str || str.length === 0;
  }
  isBlank(str) {
    return !str || /^\s*$/.test(str);
  }

  handleSubmit(event) {
    event.preventDefault();

    if (
      this.isEmpty(this.state.name) ||
      this.isBlank(this.state.name) ||
      this.isEmpty(this.state.surname) ||
      this.isBlank(this.state.surname) ||
      this.isEmpty(this.state.email) ||
      this.isBlank(this.state.email) ||
      this.isEmpty(this.state.phone) ||
      this.isBlank(this.state.phone)
    ) {
      alert("Please fill required fields.");
      return;
    }
    // if (
    //   !this.state.name ||
    //   !this.state.surname ||
    //   !this.state.email ||
    //   !this.state.phone
    // ) {
    //   alert("Please fill required fields.");
    //   return;
    // }

    fetch(process.env.REACT_APP_TRAKER_URL + "/report", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fingerprint: this.context,
        goal: process.env.REACT_APP_TRAKER_GOAL_ID,
        data: {
          name: this.state.name,
          surname: this.state.surname,
          email: this.state.email,
          phone: this.state.phone,
        },
      }),
    })
      .then((response) => response.json())
      .then(
        (result) => {
          this.showModal();
          this.setState({ name: "", surname: "", email: "", phone: "" });
        },
        (error) => {
          // console.error(error);
        }
      );
  }

  render() {
    return (
      <div>
        <form id="lead" onSubmit={this.handleSubmit}>
          <fieldset>
            <div className="row py-1 px-2">
              <input
                className="form-control"
                type="text"
                name="name"
                placeholder="First name"
                value={this.state.name}
                onChange={this.handleChange}
                required
              />
            </div>
            <div className="row py-1 px-2">
              <input
                className="form-control"
                type="text"
                name="surname"
                placeholder="Last name"
                value={this.state.surname}
                onChange={this.handleChange}
                required
              />
            </div>
            <div className="row py-1 px-2">
              <input
                className="form-control"
                type="email"
                name="email"
                placeholder="Email"
                value={this.state.email}
                onChange={this.handleChange}
                required
              />
            </div>
            <div className="row py-1 px-2">
              <input
                className="form-control"
                type="number"
                name="phone"
                placeholder="Phone"
                value={this.state.phone}
                onChange={this.handleChange}
                required
              />
            </div>
            <div className="row py-1 px-2">
              <button className="btn btn-success">
                <i>REGISTER NOW</i>
              </button>
            </div>
            <div className="row py-1 px-2">
              <small className="text-center fw-lighter">
                *By submitting you confirm that you’ve read and accepted the
                privacy policy and terms of conditions.
                <br />
                **By submitting this form, I agree to receive all marketing
                material by email, SMS and telephone.
                <br />
                ***All Trading carries risk.
                <br />
                ****Only register if you are 18 years or older.
                <br />
              </small>
            </div>
          </fieldset>
        </form>
        <Modal show={this.state.show} handleClose={this.hideModal} />
      </div>
    );
  }
}

Form.contextType = TrckdContext;

export default Form;
