import React from "react";

const Footer = () => {
    return (
        <section id="footer" style={{borderTop: "1px dashed #198754"}}>
        <div className="container pt-5 text-center">
            <p className="lh-1">
                <small style={{fontSize: "9px"}}>
                    You need to be aware that you can lose money when you invest. Terms and conditions apply, see
                    the broker's page for information.<br />

                    CFDs are complex assets and carry a high risk of losing money due to their leverage effect.
                    71% of non-professional investor accounts lose money when trading CFDs through this provider.
                    You should consider whether you understand how CFDs work or not, and whether you can afford to
                    take the high risk of losing your money.<br />

                    REGULATION & HIGH RISK INVESTMENT WARNING: Trading Forex, CFDs and Cryptocurrencies is highly
                    speculative, carries a level of risk and may not be suitable for all investors. You may lose
                    some or all of your invested capital, therefore you should not speculate with capital that you
                    cannot afford to lose. It's your obligation to check and decide weather the broker you were
                    connected to applies to all local rules and regulations and is regulated in your country
                    jurisdiction and is allowed to receive customers from your location your are in, before you fund
                    your account with the broker and start trading with it. Note that the content on this site
                    should not be considered investment advice. Investing is speculative. When investing your
                    capital is at risk.<br />

                    We are required to tell potential investors that our software's past performance does not
                    necessarily predict future results, therefore you should not speculate with capital that you
                    cannot afford to lose.<br />

                    USA REGULATION NOTICE: Option trading is not regulated within the United States. Bitcoin
                    Generation is not supervised or regulated by any financial agencies nor US agencies. Any
                    unregulated trading activity by U.S. residents is considered unlawful. Bitcoin Generation does
                    not accept customers located within the United States or holding an American
                    citizenship.<br />

                    SITE RISK DISCLOSURE: We do not accept any liability for loss or damage as a result of
                    reliance on the information contained within this website; this includes education material,
                    price quotes and charts, and analysis. Please be aware of the risks associated with trading the
                    financial markets; never invest more money than you can risk losing. The risks involved in
                    trading Forex, CFDs and Cryptocurrencies may not be suitable for all investors. We do not retain
                    responsibility for any trading losses you might face as a result of using the data hosted on
                    this site.<br />

                    LEGAL RESTRICTIONS: without limiting the undermentioned provisions, you understand that laws
                    regarding financial contracts vary throughout the world, and it is your responsibility to make
                    sure you properly comply with any law, regulation or guideline in your country of residence
                    regarding the use of the Site. To avoid any doubt, the ability to access our Site does not
                    necessarily mean that our Services and/or your activities through the Site are legal under the
                    laws, regulations or directives relevant to your country of residence.<br />

                    Please note that we do receive advertising fees for directing users to open an account with
                    the brokers/advertisers and/or for driving traffic to the advertiser website.<br />

                    We have placed cookies on your computer to help improve your experience when visiting this
                    website. You can change cookie settings on your computer at any time. Use of this website
                    indicates your acceptance of this website's Privacy Policy.<br />
                </small>
            </p>
        </div>
    </section>
    );
}

export default Footer;