import React from "react";
import "../assets/styles/Content.css"

const Content = () => {
  return (
    <div>
      <section id="content_1">
        <div className="container py-5">
          <div className="row px-5">
            <div className="col-lg-9">
              <h2 className="pb-4">
                Do You Want to Start Trading Bitcoin With the Right Tools? Use
                The Bitcoin Generation!
              </h2>
              <p className="fs-5 fst-italic fw-bold lh-1">
                Cryptocurrencies have always been something exciting to talk
                about since they were first introduced in 2009 with Bitcoins.
                While it started with Bitcoin, there are currently hundreds of
                different cryptocurrencies that people can buy or trade in
                exchange for goods and services. Thanks to Bitcoin's popularity,
                more people are looking to use cryptocurrencies for their online
                transactions each year.
              </p>
              <p className="lh-1">
                Bitcoin is popular because it's not regulated by any banking
                entity globally, meaning that you have 100% ownership of your
                assets. Another great thing about Bitcoin is that it's one of
                the safest methods to make online transactions without exposing
                your personal information to someone else. Crypto transactions
                use an advanced security software called "Blockchain," which
                stores every transaction's information on a public server. This
                is an effective security measure against fraud and hackers.
              </p>
              <p className="lh-1">
                You can get Bitcoins by exchanging currencies for Bitcoins or by
                trading goods and services for Bitcoins. However, another
                popular way to get Bitcoins is by trading. Bitcoin trading has
                been around for many years, and it's an exciting and efficient
                way to increase your profits.
              </p>
            </div>
            <div className="col-lg-3">
              <img className="img-fluid" src="/assets/img/image-3.jpeg" alt=""/>
            </div>
          </div>
        </div>
      </section>

      <section id="content_2" style={{borderTop: "1px dashed #198754"}}>
        <div className="container py-5">
          <div className="row px-5">
            <div className="col-lg-9">
              <h2 className="pb-4">How Does Bitcoin Trading Work?</h2>
              <p className="fs-5 fst-italic fw-bold lh-1">
                Trading can be a complicated activity initially, but it has a
                simple concept: you invest fiat money for Bitcoins (or other
                cryptocurrencies). Then, you speculate on their price according
                to a particular market's behavior and execute buy/sell trades
                depending on how the Bitcoin's value has changed.
              </p>
              <p className="lh-1">
                What makes trading so complicated is that it involves a lot of
                investigation and constant monitoring of the market data. A
                crypto's value tends to change unexpectedly, making it hard to
                speculate on its price at a specific point in time. This is why
                most traders prefer to spend hours monitoring the markets to
                reduce the loss margin.
              </p>
              <p className="lh-1">
                Another disadvantage of trading is that it involves strong
                emotions. If you don't concentrate and be patient with your
                trades, you may get frustrated whenever you lose a profitable
                trade. Many people don't want to get into Bitcoin trading
                because of all the reasons explained above.
              </p>
              <p className="lh-1">
                Is there a solution to this? Fortunately, there is! With the
                growing demand for Bitcoin, many developers worldwide have tried
                to create trading robots that do the trading for you. This
                software concept can save you time and reduce your risk
                potential. Our team has been working hard for years to provide
                you with an app that makes your trading more efficient, and
                that's how The Bitcoin Generation was born.
              </p>
            </div>
            <div className="col-lg-3">
              <img className="img-fluid" src="/assets/img/image-2.png" alt=""/>
            </div>
          </div>
        </div>
      </section>

      <section id="content_3" style={{borderTop: "1px dashed #198754"}}>
        <div className="container py-5">
          <div className="row px-5">
            <div
              className="col-lg-3"
              style={{backgroundImage: "url('/assets/img/image-1.png')", backgroundSize: "cover"
            }}>
              {/* <img src="./assets/img/image-1.png" className="img-fluid"> */}
            </div>
            <div className="col-lg-9">
              <h2 className="pb-4">What is The Bitcoin Generation?</h2>
              <p className="fs-5 fst-italic fw-bold lh-1">
                We wanted to create more than just a trading app; we wanted a
                reliable tool that traders could use to optimize their trading
                at any skill level. The Bitcoin Generation is an advanced
                trading app that you can use to make your trading more
                efficient. The first issue that we tackled was time. This app
                only needs a few minutes of your day to get started; after you
                set it up, you can carry on with your daily life.
              </p>
              <p className="lh-1">
                On the other hand, The Bitcoin Generation can be used from
                anywhere. Most traders stick to their computers to monitor the
                market data and execute trades. However, this is not the best
                option for people who can't take their computer to other places.
                If you have a device with a stable internet connection and
                browser, you can use The Bitcoin Generation.
              </p>
              <p className="lh-1">
                The Bitcoin Generation works by setting up particular trading
                parameters (depending on your preferences). The app uses these
                parameters to search in a specific market for trades that match
                your criteria. If it finds an opportunity for profit, it can
                execute a transaction on your behalf automatically, reducing the
                losing risks.
              </p>

              <a href="#header" className="cta text-center">
                OPEN FREE ACCOUNT! {">>"}
              </a>
            </div>
          </div>
        </div>
      </section>

      <section id="content_4" style={{borderTop: "1px dashed #198754"}}>
        <div className="container py-5">
          <div className="row px-5 text text-center text-capitalize">
            <h1 className="pb-4 fw-bold">Main benefits of Bitcoin Generation</h1>
          </div>
          <div className="row px-5 text-center">
            <h3 className="fw-light pb-4">
              We want to walk you over all of The Bitcoin Generation 's features
              so that you get a clearer idea of how it works. To start, we're
              going to give you an overview of the app's benefits:
            </h3>
            <div className="col-lg-6">
              <div className="card text-white bg-success mb-3">
                <div className="card-body">
                  <h5 className="card-title">It's Available for Most Devices</h5>
                  <p className="card-text">
                    To use The Bitcoin Generation, you only need an internet
                    connection and browser. You don't have to stick to just one
                    platform for your trading. For example, if you mainly use
                    the app from your computer at home, you can go outside and
                    get into the app from your phone. This makes it easier for
                    you to track your progress with the app and monitor the
                    market data from anywhere.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card text-white bg-success mb-3">
                <div className="card-body">
                  <h5 className="card-title">It's Constantly Updating</h5>
                  <p className="card-text">
                    An amazing thing about trading is that it's continually
                    evolving to improved and more efficient trading strategies.
                    To keep up with these trading trends, we give out constant
                    app updates. You don't have to worry about the software
                    getting outdated any time soon since we're continually
                    researching the market to work new features into The Bitcoin
                    Generation.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="card text-white bg-success mb-3">
                <div className="card-body">
                  <h5 className="card-title">
                    It Works for Beginners and Advanced Traders
                  </h5>
                  <p className="card-text">
                    Whether you have experience or not, you can use The Bitcoin
                    Generation without any issues. This app was developed so
                    that it has a smooth interface with easy-to-understand
                    instructions. We especially recommend this app for beginners
                    since it's a more friendly way to get into trading without
                    taking so many risks. However, this is still an excellent
                    tool if you're an advanced trader. If you're overworked from
                    monitoring the market data all-day-long, you can use this
                    app to get some free time to do other activities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="content_5" style={{borderTop: "1px dashed #198754"}}>
        <div className="container py-5">
          <div className="row px-5 text text-center text-capitalize">
            <h1 className="pb-4 fw-bold">
              How Can You Use The Bitcoin Generation?
            </h1>
          </div>
          <div className="row px-5 text-center">
            <h3 className="fw-light pb-4">
              This app is available for all of our members, and they can use it
              any time they feel appropriate. If you want to use The Bitcoin
              Generation for your trading, you can follow these easy steps to
              become a member of our community!
            </h3>
            <div
              className="accordion accordion-flush pb-4"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed fw-bolder bg-primary text-light"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Step One – Registration
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="flush-headingOne"
                >
                  <div className="accordion-body fw-bolder fs-5">
                    To become a member, you need to fill out our registration
                    form, which you can find further into this page. This form
                    only asks you for your basic contact information so that one
                    of our developers can contact you. When you finish sending
                    everything, we're going to contact you as soon as possible.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                  <button
                    className="accordion-button collapsed fw-bolder bg-primary text-light"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    Step Two – Setting Up Everything
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  className="accordion-collapse collapse show"
                  aria-labelledby="flush-headingTwo"
                >
                  <div className="accordion-body fw-bolder fs-5">
                    When you enter the The Bitcoin Generation app for the first
                    time, you're going to encounter several settings for you to
                    explore. We advise you to take your time and read each
                    option carefully before trading.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingThree">
                  <button
                    className="accordion-button collapsed fw-bolder bg-primary text-light"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    Step Three – Investing Your Money
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  className="accordion-collapse collapse show"
                  aria-labelledby="flush-headingThree"
                >
                  <div className="accordion-body fw-bolder fs-5">
                    We want the trading process to be as flexible and smooth as
                    possible for you. With The Bitcoin Generation, you can
                    invest any amount of money that you want. However, we
                    strongly recommend that you invest our recommended minimum
                    amount at the beginning. Doing this can significantly reduce
                    the chances of losing your investment while you get
                    familiarized with the app.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingFour">
                  <button
                    className="accordion-button collapsed fw-bolder bg-primary text-light"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseFour"
                  >
                    Step Four – Trading
                  </button>
                </h2>
                <div
                  id="flush-collapseFour"
                  className="accordion-collapse collapse show"
                  aria-labelledby="flush-headingFour"
                >
                  <div className="accordion-body fw-bolder fs-5">
                    After you set up the app, you can immediately start your
                    trading process. Keep in mind that the app is going to
                    notify you every time it finds a profitable trade for you,
                    so you have to pay close attention to your notifications so
                    that you don't miss anything! You can also change your
                    trading parameters every once in a while; this keeps
                    everything fresh and ensures that you have more
                    possibilities to find profitable trades.
                  </div>
                </div>
              </div>
            </div>
            <a href="#header" className="cta w-75 m-auto">
              Sign Up
            </a>
          </div>
        </div>
      </section>

      <section id="content_6" style={{borderTop: "1px dashed #198754"}}>
        <div className="container py-5">
          <div className="row px-5 text text-center text-capitalize">
            <h1 className="pb-4 fw-bold">Frequently Asked Questions</h1>
          </div>
          <div className="row px-5 text-center">
            <h3 className="fw-light pb-4">
              If you've never used a trading app before, there may be some
              things that you don't understand properly. This is entirely
              normal, and we want to walk you through the entire process. We've
              attached a list of our most frequently asked questions below to
              get you started.
            </h3>
            <div className="accordion accordion-flush" id="accordionFaq">
              <div className="accordion-item">
                <h2 className="accordion-header" id="accFaqHeading1">
                  <button
                    className="accordion-button collapsed fw-bolder bg-secondary text-light"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#accFaqItem1"
                    aria-expanded="false"
                    aria-controls="accFaqItem1"
                  >
                    IS BITCOIN TRADING SAFE?
                  </button>
                </h2>
                <div
                  id="accFaqItem1"
                  className="accordion-collapse collapse"
                  aria-labelledby="accFaqHeading1"
                  data-bs-parent="#accordionFaq"
                >
                  <div className="accordion-body">
                    Trading can be safe if you know who you're trusting your
                    money with. We advise you to investigate each platform that
                    you plan on using as thoroughly as possible before you start
                    trading any cryptocurrency. While there are many trustworthy
                    trading platforms, there are also many scam platforms that
                    work with fake software to make you believe that it works
                    and can make you rich. The main goal of those scam sites is
                    to get your money and billing information. After they get
                    that information, they usually sell it to other people,
                    which can be dangerous for your cyber-security. If you plan
                    on using The Bitcoin Generation for your trading, we
                    encourage you to clarify every question you may have with
                    our support team. We want your experience to go smoothly and
                    efficiently, and that can only be achieved if there's proper
                    communication.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="accFaqHeading2">
                  <button
                    className="accordion-button collapsed fw-bolder bg-secondary text-light"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#accFaqItem2"
                    aria-expanded="false"
                    aria-controls="accFaqItem2"
                  >
                    IS THERE ANY WAY TO TELL HOW MUCH I CAN MAKE WITH Bitcoin
                    Generation?
                  </button>
                </h2>
                <div
                  id="accFaqItem2"
                  className="accordion-collapse collapse"
                  aria-labelledby="accFaqHeading2"
                  data-bs-parent="#accordionFaq"
                >
                  <div className="accordion-body">
                    Unfortunately, there's no precise way to tell how much money
                    you can make with any trading app (Including ours). This is
                    because cryptocurrencies are volatile, and their value may
                    change drastically from time to time. The only thing you can
                    do is speculate on their value, which usually involves a
                    high-risk factor. However, don't let that discourage you
                    from trading! If you take your time to research the market
                    data and new trading strategies, you may have higher chances
                    of finding the best trades for your specific criteria. If
                    any site tries to tell you how much money you can make after
                    a certain amount of time with their app, it's likely a scam.
                    While trading apps can reduce your chances of losing trading
                    opportunities, they're not perfect and can fail sometimes.
                    We're doing the best we can to increase our app's success
                    rate with every update, but trading is unpredictable, so
                    there's always going to be some risk in each transaction.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="accFaqHeading3">
                  <button
                    className="accordion-button collapsed fw-bolder bg-secondary text-light"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#accFaqItem3"
                    aria-expanded="false"
                    aria-controls="accFaqItem3"
                  >
                    CAN I WITHDRAW MY MONEY AT ANY TIME?
                  </button>
                </h2>
                <div
                  id="accFaqItem3"
                  className="accordion-collapse collapse"
                  aria-labelledby="accFaqHeading3"
                  data-bs-parent="#accordionFaq"
                >
                  <div className="accordion-body">
                    Yes! We don't charge you any additional transaction fees, so
                    your money is entirely yours from the start to the end of
                    the trading process. While we don't charge you any
                    additional fees, your bank institution might. Keep that in
                    mind before making any withdrawals.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="content_7" style={{borderTop: "1px dashed #198754"}}>
        <div className="container py-5">
          <div className="row px-5 text text-center text-capitalize">
            <h1 className="pb-4 fw-bold">
              If You Want to Start Your Trading Journey, Use The Bitcoin
              Generation to Help You Out!
            </h1>
            <p>
              Keep in mind that becoming better at trading takes time, so in
              this case, patience is one of the most important things that you
              should have in your journey. We want your future success story to
              be heard among many others from this community, so if you're ready
              to join our team of passionate traders, fill out our registration
              form, and let's get started!
            </p>
            <a href="#header" className="cta w-75 m-auto">
              Register Now
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Content;