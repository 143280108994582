import React from "react";
import Form from '../components/Form.js'
import "../assets/styles/Header.css"

const Header = () => {
  return (
    <section id="header">
      <div className="container py-5">
        <div className="row px-5">
          <div className="col-lg-8 py-5">
            <h1 className="pb-4">Invest in Bitcoin</h1>
            <h2 className="pb-4">
              Experts predict Bitcoin will reach $318,000* by the end of the
              year. Invest now with Bitcoin Generation and take advantage.
            </h2>
            <figcaption className="blockquote-footer">
              *Source: <cite title="Source">Forbes.com</cite>
            </figcaption>
          </div>
          <div className="col-lg-4 py-5" id="lead_form">
            {/* <FormComponent /> */}
            <Form />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Header;