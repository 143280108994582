import { createContext, useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSearchParams } from "react-router-dom";

export const TrckdContext = createContext();

export const TrckdProvider = ({ children }) => {
  const [fingerprint, setFingerprint] = useState(null);
  const [cookie, setCookie] = useCookies('_trid');
  const [query] = useSearchParams();

  const resolveFingerprint = useCallback(() => {
    if (cookie._trid) {
      setFingerprint(cookie._trid)
    } else if (query.has('fingerprint')) {
      setCookie('_trid', query.get('fingerprint'));
      setFingerprint(query.get('fingerprint'));
    } else {
      fetch(process.env.REACT_APP_TRAKER_URL + "/fingerprint", {
        method: "POST",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          product: process.env.REACT_APP_TRAKER_PRODUCT_ID,
          origin: encodeURI(window.location.href),
          referer: window.location.referrer
            ? encodeURI(window.location.referrer)
            : null,
        }),
      })
        .then((response) => response.json())
        .then(
          (result) => {
            if(result.status.code == 0) {
              setCookie("_trid", result.fingerprint);
              setFingerprint(result.fingerprint);
            }
          },
          (error) => {
            console.error(error);
          }
        );
    }
  }, []);

  useEffect(() => {
    resolveFingerprint();
  }, []);

  return (
    <TrckdContext.Provider value={fingerprint}>
      {children}
    </TrckdContext.Provider>
  );
};